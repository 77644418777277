.webcrossing-text {
  @include spaceX(2.5rem);

  &[data-appearance="top-bottom"] {
    > .wrapper {
      @include content(small);

      .section {
        &.bottom {
          display:flex;
          flex-wrap:wrap;
          justify-content: center;
          gap: 8rem;
          margin-top: 12rem;

          div {
            width:calc((100% - 8rem) / 2);
          }
        }

        h4 {
          text-align: center;
        }

        h3 {
          text-align: center;
          max-width:92rem;
        }

        div {
          max-width:55rem;
          text-align: justify;
        }
      }
    }
  }

  &[data-appearance="full-width"] {
    > .wrapper {
      @include content(medium);

      .section {
        &.bottom {
          margin-top: 12rem;
        }

        h4 {
          text-align: center;
        }

        h3 {
          text-align: center;
        }
      }
    }
  }

  &[data-appearance="left-right"] {
    > .wrapper {
      @include content(small);
      display:flex;
      flex-wrap:wrap;
      justify-content: center;
      gap: 14rem;

      .section {
        width:calc((100% - 14rem) / 2);

        h3 {
          max-width:92rem;
        }

        div {
          max-width:55rem;
          margin-bottom: 2rem;
        }
      }
    }
  }

  > .wrapper {
    @include spaceY(16rem, 28rem);

    .section {
      h4 {
        margin-bottom: 3rem;
        margin-left:auto;
        margin-right:auto;
        color:$colorPrimary;
      }

      h3 {
        margin-left:auto;
        margin-right:auto;
        color:$colorPrimary;
      }

      > div {
        font-weight: 400;
        font-size: 2rem;
        line-height: 3rem;
        letter-spacing: 1.5px;
        color:$colorPrimary;

        * {
          font-weight: 400;
          font-size: 2rem;
          line-height: 3rem;
          letter-spacing: 1.5px;
          color:$colorPrimary;
        }

        a {
          text-decoration: underline;
        }
      }
    }
  }
}

.modal-content {
  .webcrossing-text {
    padding-left: 0;
    padding-right: 0;

    &[data-appearance="full-width"] {
      > .wrapper {
        padding-top: 0;
        padding-bottom: 0;

        .section {
          &.bottom {
            margin-top: 4rem;
          }

          h3 {
            margin-top:0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  .webcrossing-text {
    &[data-appearance="left-right"] {
      > .wrapper {
        gap: 4rem;

        .section {
          width:calc((100% - 4rem) / 2);

          h3 {
            max-width:92rem;
          }

          > div {
            max-width:55rem;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .webcrossing-text {
    @include spaceX(1.5rem);

    &[data-appearance="top-bottom"] {
      > .wrapper {
        .section {
          &.bottom {
            gap: 4rem;
            margin-top: 6rem;

            div {
              width: 100%;
            }
          }
        }
      }
    }

    &[data-appearance="left-right"] {
      > .wrapper {
        .section {
          width:100%;

          h3 {
            max-width:42rem;
            text-align:center;
          }

          h4 {
            text-align:center;
          }

          > div {
            max-width: 55rem;
            margin-left: auto;
            margin-right: auto;
            text-align:justify;
          }
        }
      }
    }

    > .wrapper {
      padding-top: 6rem;
      padding-bottom: 8rem;

      .section {
        h4 {
          margin-bottom: 2rem;
        }

        div {
          font-size: 1.8rem;
          line-height: 2.7rem;

          * {
            font-size: 1.8rem;
            line-height: 2.7rem;
          }
        }
      }
    }
  }
}
