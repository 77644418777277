.webcrossing-product-slider {
  overflow: hidden;
  @include spaceX(2.5rem);

  &[data-design="transparent"] {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85));

    > .wrapper {
      .section {
        &.info {
          h4 {
            color:$colorWhite;
          }
          h3 {
            color:$colorWhite;
          }
          a {
            color:$colorWhite;

            &.arrow {
              &:hover {
                border-color: $colorWhite;
              }
            }
          }
        }
      }
    }
  }

  &[data-design="dark"] {
    background-color: $colorBlack;

    > .wrapper {
      .section {
        &.info {
          h4 {
            color:$colorWhite;
          }
          h3 {
            color:$colorWhite;
          }
          a {
            color:$colorWhite;

            &.arrow {
              &:hover {
                border-color: $colorWhite;
              }
            }
          }
        }
      }
    }
  }

  &[data-design="light"] {
    background-color: $colorWhite;

    > .wrapper {
      .section {
        &.info {
          h4 {
            color:$colorPrimary;
          }
          h3 {
            color:$colorPrimary;
          }
          a {
            color:$colorPrimary;

            &.arrow {
              &:hover {
                border-color: $colorPrimary;
              }
            }
          }
        }
      }
    }
  }

  > .wrapper {
    @include content(medium);
    @include spaceY(20rem);
    display:flex;
    flex-wrap:wrap;
    justify-content: space-between;

    .section {
      &.info {
        width: 35rem;

        h4 {
          margin-bottom:2rem;
        }

        h3 {
          margin-bottom:7rem;
          max-width: 32rem;
        }

        a {
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 3rem;
          letter-spacing: 4px;
          text-transform: uppercase;
          display: inline-block;
          padding-bottom: 4px;

          &.arrow {
            border-bottom:2px solid;
            border-color:transparent;

            &:before {
              font-family: "Font Awesome 6 Pro";
              content: "\f078";
              width: 2rem;
              display: inline-block;
            }

            &:hover {
              &:before {
                content: "\f054";
              }
            }
          }
        }
      }

      &.slider {
        width: calc(100% - 35rem);

        .splide {
          .splide__arrows {
            width: 105.3rem;
            position: absolute;
            left: 0;
            top: 38%;
            z-index: 1;

            .splide__arrow {
              background: $colorPrimary;
              opacity: 1;
              height: 5rem;
              width: 5rem;
              top: 50%;
              transform: translateY(-50%);

              &--prev {
                display:none;
              }

              &--next {
                right: -4rem;

                &:before {
                  font-family: "Font Awesome 6 Pro";
                  display: inline-block;
                  font-weight: 300;
                  content: "\f061";
                  color:$colorWhite;
                  font-size: 2rem;
                }
              }

              svg {
                display:none;
                fill:$colorWhite;
              }
            }
          }

          .splide__track {
            width: 144rem;
            padding-top: 0.3rem;

            .splide__list {
              .product-item {
                max-width:33rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $largeDesktop) {
  .webcrossing-product-slider {
    > .wrapper {
      .section {
        &.info {
          width: 32rem;
        }

        &.slider {
          width: calc(100% - 32rem);

          .splide {
            .splide__arrows {
              width: 59.3rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  .webcrossing-product-slider {
    > .wrapper {
      .section {
        &.info {
          width:100%;

          h3 {
            margin-bottom:4rem;
          }

          a {
            margin-bottom:4rem;
          }
        }

        &.slider {
          width:100%;

          .splide {
            .splide__arrows {
              width: 66.3rem;

              .splide__arrow {
                height: 3rem;
                width: 3rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .webcrossing-product-slider {
    @include spaceX(1.5rem);

    > .wrapper {
      padding-top: 4rem;
      padding-bottom: 4rem;

      .section {
        &.slider {
          .splide {
            .splide__arrows {
              width: 100%;

              .splide__arrow {
                width:2rem;
                height:2rem;

                &--next {
                  right: -1rem;

                  &:before {
                    font-size: 1.2rem;
                  }
                }
              }
            }
          }
        }

        &.info {
          h4 {
            margin-bottom:1rem;
          }

          a {
            font-size: 1.1rem;
            line-height: 2.4rem;
            margin-bottom:2rem;
          }
        }
      }
    }
  }
}
