.webcrossing-image-grid {
  background-color: $colorWhite;

  > .wrapper {
    @include content(max);
    display:flex;
    flex-wrap:wrap;
    justify-content: space-between;
    gap:1rem;
    padding: 0;

    .section {
      &.left {
        width:calc(((100% - 1rem) / 5) * 3);
        overflow: hidden;
      }

      &.right {
        width:calc(((100% - 1rem) / 5) * 2);
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
      }

      .box {
        position:relative;
        width:100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        margin:0;

        &:hover {
          .overlay {
            display: flex;
          }
        }

        .news {
          position:absolute;
          z-index:1;
          width:21rem;
          height:21rem;
          background-color:$colorPrimary;
          bottom:6rem;
          left:6rem;
          border-radius: 50%;
          padding: 3rem 3.2rem;
          overflow: hidden;

          h3 {
            font-weight: 400;
            font-size: 2rem;
            line-height: 3rem;
            text-align: center;
            letter-spacing: 1.5px;
            color:$colorWhite;
            margin-top:0;
            margin-bottom: 2rem;
          }

          h4 {
            text-align: center;
            line-height: 2.3rem;
            color:$colorWhite;
            margin:0;

            &.red {
              color:$colorSecondary
            }
          }
        }

        .overlay {
          display: none;
          position:absolute;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
          width:100%;
          height:100%;
          z-index:1;
          justify-content: center;
          align-items: center;

          a {
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 3rem;
            letter-spacing: 4px;
            text-transform: uppercase;
            color:$colorWhite;
            text-align: center;
            padding-bottom: 4px;

            &.arrow {
              border-bottom:2px solid;
              border-color:transparent;

              &:before {
                font-family: "Font Awesome 6 Pro";
                content: "\f078";
                width: 2rem;
                display: inline-block;
              }

              &:hover {
                border-color:$colorWhite;

                &:before {
                  content: "\f054";
                }
              }
            }
          }
        }

        &.big {
          padding-bottom: 67%;
        }

        &.small {
          flex: 1;
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .webcrossing-image-grid {
    > .wrapper {
      gap: 0.4rem;

      .section {
        &.left {
          width:100%;
        }

        &.right {
          width:100%;
          flex-direction: row;
          gap: 0.4rem;
        }

        .box {
          &.big {
            padding-bottom: 64.2%;
            background-size: 110%;
            background-position: top center;
          }

          &.small {
            padding-bottom: 64.2%;
            width:calc((100% - 1rem) / 2);
          }

          .overlay {
            display:flex;
            background: none;
            align-items: flex-end;

            a {
              padding-bottom:0;
              margin-bottom: 2rem;
              text-transform: none;
              font-size: 1.1rem;
              line-height: 2rem;
              letter-spacing: 1.5px;

              &.arrow {
                border-bottom: 1px solid;
                border-color: $colorSecondary;

                &:before {
                  display:none;
                }

                &:hover {
                  border-color:$colorSecondary;
                }
              }
            }
          }

          .news {
            top: 0;
            bottom: auto;
            left: 50%;
            transform: translate(-50%, 0);
            background-color:transparent;
            width: 100%;
            height: auto;
            border-radius: 0;
            padding: 0 1rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            h3 {
              color:$colorPrimary;
              font-size: 1.1rem;
              line-height: 2rem;
              margin-bottom: 0;
              width: 100%;
            }

            h4 {
              color:$colorPrimary;
              font-size: 1.1rem;

              &.red {
                margin-right:0.5rem;
              }
            }
          }
        }
      }
    }
  }
}