.webcrossing-image-info {
  @include spaceX(2.5rem);
  position:relative;
  z-index: 0;
  background-color:$colorGrey;

  &:before {
    content:"";
    display: block;
    background-color:$colorPrimary;
    width:100%;
    height:50rem;
    position:absolute;
    left:0;
    top:0;
    z-index: -1;
  }

  > .wrapper {
    @include content(tiny);
    @include spaceY(20rem);
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    gap: 8rem;

    .section {
      h3 {
        font-weight: 500;
        font-size: 7.5rem;
        line-height: 9rem;
        text-align: center;
        letter-spacing: 1px;
        color:$colorWhite;
      }

      h4 {
        text-align: center;
        color:$colorWhite;
      }

      ul {
        list-style: none;

        li {
          font-weight: 400;
          font-size: 3.4rem;
          line-height: 4.4rem;
          color:$colorPrimary;
          letter-spacing: 1px;
        }
      }

      &.top {
        width:100%;

        .box {
          &.headline {
            margin-bottom: -5rem;
            position: relative;
            z-index: 1;

            h3 {
              max-width:92.7rem;
              width:100%;
              margin: 0 auto;
            }

            h4 {
              max-width:33.3rem;
              margin-left:auto;
              margin-right:auto;
              margin-bottom: 3rem;
            }
          }

          &.image {
            width: 100%;
            margin:0;
            position: static;
            background: transparent;

            ul {
              max-width: 92.7rem;
              max-height: 53.2rem;
              background-color:$colorGrey;

              li {
                display:none;
                position: relative;
                padding-bottom: 57.4%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;

                &:after {
                  content:"";
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 18.77%, rgba(0, 0, 0, 0) 69.35%);
                }

                &.active {
                  display:block;
                }
              }
            }
          }
        }
      }

      &.left {
        width: 32rem;

        ul {
          position: relative;
          border-right: 1px solid $colorPrimary;

          li {
            &[data-type="arrow"] {
              @include overlay($width: 3rem, $height: 3rem, $z-index: 5);
              transition: top 0.5s;
              font-family: "Font Awesome 6 Pro";
              font-weight: 300;
              color:$colorSecondary;

              &:after {
                content: "\f061";
              }
            }

            &[data-type="trigger"] {
              margin-bottom:2.5rem;
              cursor:pointer;
              transition: margin 0.5s;

              &.active {
                color:$colorSecondary;
                margin-left: 6rem;
                transition: margin 0.5s;
              }
            }
          }
        }
      }

      &.right {
        width:calc(100% - 40rem);

        ul {
          li {
            display:none;

            &.active {
              display:block;
            }

            h4 {
              color:$colorPrimary;
              text-align: left;
              margin-bottom:3rem;
            }

            p {
              font-weight: 400;
              font-size: 2rem;
              line-height: 3rem;
              letter-spacing: 1.5px;
              color:$colorPrimary;
            }

            a {
              font-weight: 500;
              font-size: 1.4rem;
              line-height: 3rem;
              letter-spacing: 4px;
              text-transform: uppercase;
              color:$colorPrimary;
              text-align: left;
              padding-bottom: 4px;
              margin-top:4rem;
              display:inline-block;

              &.arrow {
                border-bottom:2px solid;
                border-color:transparent;

                &:before {
                  font-family: "Font Awesome 6 Pro";
                  content: "\f078";
                  width: 2rem;
                  display: inline-block;
                }

                &:hover {
                  border-color:$colorPrimary;

                  &:before {
                    content: "\f054";
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .webcrossing-image-info {
    @include spaceX(1.5rem);

    &:before {
      height: 36rem;
    }

    > .wrapper {
      padding-top: 4.5rem;
      padding-bottom: 4.5rem;
      gap: 4rem;

      .section {
        h3 {
          font-size: 2.7rem;
          line-height: 2.9rem;
        }

        ul {
          li {
            font-size: 2.4rem;
            line-height: 2.9rem;
          }
        }

        &.top {
          .box {
            &.image {
              padding-left: 3rem;
              padding-right: 3rem;

              ul {
                li {
                  padding-bottom: 102.6%;
                }
              }
            }

            &.headline {
              margin-bottom: -1rem;

              h3 {
                max-width: 34rem;
              }
            }
          }
        }

        &.left {
          width:auto;
          border-right: 0;

          ul {
            border-right: 0;

            li {
              &[data-type="trigger"] {
                margin-bottom: 1rem;
              }

              &[data-type="trigger"] {
                &.active {
                  margin-left: 4rem;
                }
              }
            }
          }
        }

        &.right {
          width:100%;
          text-align: center;

          ul {
            li {
              h4 {
                text-align: center;
                margin-bottom:2rem;
              }

              p {
                font-size: 1.4rem;
                line-height: 2rem;
              }

              a {
                text-align: center;
                font-size: 1.1rem;
                line-height: 2.4rem;
                margin-top: 2rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $phone) {
  .webcrossing-image-info {
    &:before {
      height: 18rem;
    }
  }
}