.webcrossing-wysiwyg {
  @include spaceX(2.5rem);

  &[data-design="medium"] {
    > .wrapper {
      @include content(medium);
    }
  }

  &[data-design="small"] {
    > .wrapper {
      @include content(small);
    }
  }

  &[data-design="tiny"] {
    > .wrapper {
      @include content(tiny);
    }
  }

  > .wrapper {
    @include spaceY(8.5rem);

    h3 {
      margin-left:auto;
      margin-right:auto;
      color:$colorPrimary;
      margin-bottom: 2rem;
    }

    > div {
      font-weight: 400;
      font-size: 2rem;
      line-height: 3rem;
      letter-spacing: 1.5px;
      color:$colorPrimary;

      * {
        font-weight: 400;
        font-size: 2rem;
        line-height: 3rem;
        letter-spacing: 1.5px;
        color:$colorPrimary;
      }

      &[data-element="description"] {
        ul {
          list-style: inherit;
          padding-left: 4rem;
          margin: 2rem 0;
        }
      }
    }
  }
}