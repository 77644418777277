.webcrossing-image-carousel {
  background-color: $colorWhite;

  &[data-focus="1"] {
    > .wrapper {
      .section {
        &.carousel {
          .box {
            &.splide {
              .splide__arrows {
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }
    }
  }

  &[data-focus="0"] {
    > .wrapper {
      .section {
        &.carousel {
          .box {
            &.splide {
              .splide__arrows {
                left: 36rem;
                transform: translate(0, -50%);
              }
            }
          }
        }
      }
    }
  }

  > .wrapper {
    @include content(max);
    @include spaceY(5rem);
    display:flex;
    flex-wrap:wrap;
    justify-content: center;

    .section {
      width:100%;

      &.carousel {
        .box {
          &.splide {
            width: 100%;
            position: relative;

            .splide__arrows {
              width: 105rem;
              position: absolute;
              top: 50%;
              z-index: 1;

              .splide__arrow {
                background: $colorWhite;
                opacity: 1;
                height: 5rem;
                width: 5rem;

                &--prev {
                  display:none;
                  left: -4rem;

                  &:before {
                    font-family: "Font Awesome 6 Pro";
                    display: inline-block;
                    font-weight: 300;
                    content: "\f060";
                    color:$colorPrimary;
                    font-size: 2rem;
                  }
                }

                &--next {
                  right: -4rem;

                  &:before {
                    font-family: "Font Awesome 6 Pro";
                    display: inline-block;
                    font-weight: 300;
                    content: "\f061";
                    color:$colorPrimary;
                    font-size: 2rem;
                  }
                }

                svg {
                  display:none;
                  fill:$colorPrimary;
                }
              }
            }

            .splide__track {
              .splide__list {
                .splide__slide {

                  img {
                    width:100%;
                  }

                  .overlay {
                    position: absolute;
                    width: 100%;
                    top: 0;
                    left: 0;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 1rem;

                    h3 {
                      font-weight: 400;
                      font-size: 3.4rem;
                      line-height: 4.4rem;
                      color:$colorWhite;
                      text-align:center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $largeDesktop) {
  .webcrossing-image-carousel {
    &[data-focus="0"] {
      > .wrapper {
        .section {
          &.carousel {
            .box {
              &.splide {
                .splide__arrows {
                  left: 29rem;
                }
              }
            }
          }
        }
      }
    }

    > .wrapper {
      .section {
        &.carousel {
          .box {
            &.splide {
              .splide__arrows {
                width: 84rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  .webcrossing-image-carousel {
    &[data-focus="0"] {
      > .wrapper {
        .section {
          &.carousel {
            .box {
              &.splide {
                .splide__arrows {
                  left: 23.5rem;
                }
              }
            }
          }
        }
      }
    }

    > .wrapper {
      .section {
        &.carousel {
          .box {
            &.splide {
              .splide__arrows {
                width: 66rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .webcrossing-image-carousel {
    &[data-focus="0"] {
      > .wrapper {
        .section {
          &.carousel {
            .box {
              &.splide {
                .splide__arrows {
                  left: 19.5rem;
                }
              }
            }
          }
        }
      }
    }

    > .wrapper {
      @include spaceY(2rem);

      .section {
        &.carousel {
          .box {
            &.splide {
              .splide__arrows {
                width: 11rem;

                .splide__arrow {
                  width:2rem;
                  height:2rem;

                  &--next {
                    &:before {
                      font-size: 1.2rem;
                    }
                  }
                }
              }

              .splide__track {
                .splide__list {
                  .splide__slide {
                    .overlay {
                      h3 {
                        font-size: 1.8rem;
                        line-height: 2.9rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
