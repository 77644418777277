.webcrossing-teaser {
  @include spaceX(2.5rem);

  > .wrapper {
    @include spaceY(16rem);
    @include content(small);
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    gap: 14rem;

    .section {
      width:calc((100% - 14rem) / 2);

      &.left {
        h3 {
          margin-left:auto;
          margin-right:auto;
          color:$colorPrimary;
          max-width:92rem;
          position: sticky;
          top:10.4rem;
        }
      }

      &.right {
        .teaser {
          &.not-default {
            margin-top:12rem;

            .description {
              color:$colorPrimary;
              max-width:55rem;
              margin-bottom: 2rem;
              font-size: 1.3rem;
              font-weight: 400;
              line-height: 2.4rem;
              letter-spacing: 0.195px;
              margin-top: 3rem;
              order:3;

              * {
                font-weight: 400;
                font-size: 1.3rem;
                line-height: 2.4rem;
                letter-spacing: 0.195px;
                color:$colorPrimary;
              }
            }
          }

          &.default {
            .description {
              font-weight: 400;
              font-size: 2rem;
              line-height: 3rem;
              letter-spacing: 1.5px;
              color:$colorPrimary;
              max-width:55rem;

              * {
                font-weight: 400;
                font-size: 2rem;
                line-height: 3rem;
                letter-spacing: 1.5px;
                color:$colorPrimary;
              }
            }
          }

          .image {
            padding-bottom: 77.3%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }

          .info {
            display:flex;
            flex-wrap:wrap;
            justify-content: space-between;
            align-items: baseline;
          }

          h3 {
            font-size: 3.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: 4.4rem;
            color:$colorPrimary;
            margin-top:4rem;
            order:1;
          }

          a {
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 3rem;
            letter-spacing: 4px;
            text-transform: uppercase;
            color:$colorPrimary;
            text-align: center;
            padding-bottom: 4px;
            order:2;

            &.arrow {
              border-bottom:2px solid;
              border-color:transparent;

              &:before {
                font-family: "Font Awesome 6 Pro";
                content: "\f078";
                width: 2rem;
                display: inline-block;
              }

              &:hover {
                border-color:$colorPrimary;

                &:before {
                  content: "\f054";
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  .webcrossing-teaser {
    > .wrapper {
      gap: 4rem;

      .section {
        width:calc((100% - 4rem) / 2);
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .webcrossing-teaser {
    > .wrapper {
      &.splide {
        visibility: visible !important;

        .splide__list {
          display: block !important;
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .webcrossing-teaser {
    @include spaceX(1.5rem, 0);

    > .wrapper {
      @include spaceY(8rem);
      gap: 5rem;

      .splide__arrows {
        width: 100%;
        position:absolute;
        top: 42%;

        .splide__arrow {
          width:2rem;
          height:2rem;
          background: $colorPrimary;
          opacity: 1;

          &--prev {
            display:none;
          }

          &--next {
            left: 27rem;

            &:before {
              font-family: "Font Awesome 6 Pro";
              display: inline-block;
              font-weight: 300;
              content: "\f061";
              color:$colorWhite;
              font-size: 1.2rem;
            }
          }

          svg {
            display:none;
            fill:$colorWhite;
          }
        }
      }

      .section {
        width: 100%;

        &.left {
          h3 {
            max-width: 42rem;
            text-align: center;
          }
        }

        &.right {
          .teaser {
            &.not-default {
              margin-top: 0;

              .description {
                order:2;
                margin-top: 1.5rem;
                margin-bottom: 1rem;
              }
            }

            &.default {
              display: none;

              h4 {
                text-align: center;
              }

              > div {
                max-width: 55rem;
                margin-left: auto;
                margin-right: auto;
                text-align: justify;
              }
            }

            .image {
              padding-bottom: 111%;
            }

            .info {
              flex-wrap:wrap;
            }

            h3 {
              font-size: 2.4rem;
              line-height: 2.9rem;
              width:100%;
              order:1;
            }

            a {
              font-size: 1.1rem;
              line-height: 2.4rem;
              margin-top: 2rem;
              order:3;
            }
          }
        }
      }
    }
  }
}
