.column.main {
  [data-content-type="html"] {
    @include content(medium);

    iframe {
      width: 100%;
      height: 60rem;
    }

    .block {
      &.newsletter {
        padding-bottom: 10rem;
        padding-top: 10rem;
      }
    }

    .form {
      &.contact {
        padding-bottom: 10rem;
        padding-top: 10rem;
      }
    }
  }
}
