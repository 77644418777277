.webcrossing-marquee {
  @include content(max);
  background-color: $colorSecondary;
  overflow:hidden;
  max-height:50px;

  > .wrapper {
    .js-marquee-wrapper {
      .js-marquee {
        display:flex;
        justify-content: space-between;
        gap:10rem;
      }
    }

    .item {
      @include spaceY(1rem);
      display: inline-block;

      &.hidden {
        visibility: hidden;
      }

      a,
      span {
        color:$colorWhite;
        font-weight: 500;
      }
    }
  }
}