.webcrossing-footer {
  > .wrapper {
    @include spaceY(12.5rem);
    @include spaceX(3.5rem);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85));

    .main {
      @include content(medium);
      display:flex;
      flex-wrap:wrap;
      justify-content: space-between;
      align-items: center;

      .section {
        letter-spacing: 1.5px;

        p {
          color:$colorWhite;
        }

        a {
          color:$colorWhite;
        }

        &.left {
          .address {
            font-size: $fontSizeDefault;
            line-height: $lineHeightDefault;
          }
        }

        &.middle {
          display: flex;
          flex-wrap: wrap;
          max-width: 52rem;

          > div {
            width:100%;
          }

          .links {
            display: flex;
            justify-content: center;
            gap: 2rem;
            margin-top: 20px;

            > li {
              a {
                width: 40px;
                height: 40px;
                background-color: #fff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                letter-spacing: 0;

                i {
                  color:$colorBlack;
                  font-size: 2.3rem;
                }
              }
            }
          }

          p {
            font-weight: 500;
            font-size: 7rem;
            line-height: 7.4rem;
            margin: 0;

            &.center {
              text-align: center;
            }

            &.right {
              text-align: right;
            }

            span {
              &.skiing {
                background: url($imageDir + "skiing-round.png") no-repeat;
                background-size: 100%;
                box-sizing: border-box;
                width: 8.6rem;
                height: 8.6rem;
                display: inline-block;
                vertical-align: middle;
              }
            }
          }
        }

        &.right {
          text-align: right;

          p {
            &.small {
              margin-top:1.5rem;
            }
          }
        }
      }
    }
    .bottom {
      @include content(medium);
      margin-top: 11rem;

      .section {
        &.full {
          width:100%;
        }

        ul {
          list-style: none;
          display:flex;
          flex-wrap:wrap;
          justify-content: space-between;

          li {
            color:$colorWhite;
            letter-spacing: 4px;
            text-transform: uppercase;
            font-weight: 500;
            font-size:1.4rem;

            a {
              color:$colorWhite;
              font-weight: 500;
              font-size:1.4rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  .webcrossing-footer {
    > .wrapper {
      @include spaceY(8.5rem);

      .bottom {
        margin-top: 8rem;

        .section {
          ul {
            justify-content: center;
            gap: 2rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $smallDesktop) {
  .webcrossing-footer {
    > .wrapper {
      @include spaceY(8.5rem);

      .main {
        gap:4rem;

        .section {
          width:100%;
          display: flex;
          justify-content: center;

          &.left {
            order:2;
            text-align: center;
          }

          &.middle {
            max-width: none;
            order:1;

            > div {
              max-width: 52rem;
            }
          }

          &.right {
            order:3;
            text-align: center;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .webcrossing-footer {
    > .wrapper {
      @include spaceY(4.5rem);

      .main {
        .section {
          &.left {
            .address {
              font-size: 1.4rem;
              line-height: 2rem;
            }
          }

          &.middle {
            p {
              font-size: 4rem;
              line-height: 5.5rem;

              span {
                &.skiing {
                  width: 5.5rem;
                  height: 5.5rem;
                }
              }
            }

            > div {
              max-width: 38rem;
              word-wrap: break-word;
            }
          }
        }
      }

      .bottom {
        margin-top: 5rem;

        .section {
          ul {
            gap:1rem;

            li {
              a {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
}

