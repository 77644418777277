.webcrossing-image-slider {
  @include spaceX(2.5rem);
  background-color: $colorPrimary;
  position:relative;
  z-index: 0;

  &:before {
    content:"";
    display: block;
    background-color:$colorWhite;
    width:100%;
    height:16rem;
    position:absolute;
    left:0;
    top:0;
    z-index: -1;
  }

  > .wrapper {
    @include content(medium);
    @include spaceY(12rem);
    display:flex;
    flex-wrap:wrap;
    justify-content: center;

    .section {
      width:100%;

      &.slider {
        display:flex;
        flex-wrap:wrap;
        align-items: center;

        .box {
          width:calc((100% - 84.3rem) / 2);

          &.splide {
            width:100%;
            max-width:84.3rem;
            position: relative;

            .splide__arrows {
              .splide__arrow {
                background: $colorPrimary;
                opacity: 1;
                height: 5rem;
                width: 5rem;
                bottom: -2.5rem;
                top: auto;
                transform: none;

                &--prev {
                  left: -2.5rem;

                  &:before {
                    font-family: "Font Awesome 6 Pro";
                    display: inline-block;
                    font-weight: 300;
                    content: "\f060";
                    color:$colorWhite;
                    font-size: 2rem;
                  }
                }

                &--next {
                  right: -2.5rem;

                  &:before {
                    font-family: "Font Awesome 6 Pro";
                    display: inline-block;
                    font-weight: 300;
                    content: "\f061";
                    color:$colorWhite;
                    font-size: 2rem;
                  }
                }

                svg {
                  display:none;
                  fill:$colorWhite;
                }
              }
            }

            .splide__track {
              .splide__list {
                .splide__slide {
                  display: inline-block;
                  background: linear-gradient(270deg, #000000 -10.08%, rgba(0, 0, 0, 0) 18.68%);

                  img {
                    z-index:-1;
                    position:relative;
                  }
                }
              }
            }
          }

          &.headline {
            h3 {
              font-weight: 500;
              font-size: 7.5rem;
              line-height: 9rem;
              letter-spacing: 1px;
              color:$colorSecondary;
              transform: rotate(-90deg);
              transform-origin: center;

              &:first-line {
                color:$colorWhite;
              }
            }
          }

          &.subheadline {
            h4 {
              color:$colorWhite;
              margin-left: -4rem;
              position: relative;
              max-width: 32rem;
            }
          }
        }
      }

      &.description {
        padding:9rem 0 0 0;
        max-width:70rem;
        margin: 0 auto;
        text-align: center;

        p {
          font-weight: 400;
          font-size: 2rem;
          line-height: 3rem;
          text-align: center;
          letter-spacing: 1.5px;
          color:$colorWhite;
          margin-bottom:5rem;
        }

        a {
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 3rem;
          letter-spacing: 4px;
          text-transform: uppercase;
          color:$colorWhite;
          text-align: center;
          padding-bottom: 4px;

          &.arrow {
            border-bottom:2px solid;
            border-color:transparent;

            &:before {
              font-family: "Font Awesome 6 Pro";
              content: "\f078";
              width: 2rem;
              display: inline-block;
            }

            &:hover {
              border-color:$colorWhite;

              &:before {
                content: "\f054";
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $largeDesktop) {
  .webcrossing-image-slider {
    &:before {
      height:40rem;
    }

    > .wrapper {
      .section {
        &.slider {
          .box {
            &.headline {
              width:100%;
              margin-bottom: 2rem;

              h3 {
                transform: none;
                color:$colorSecondary;
                max-width: 40rem;
                margin-left: auto;
                margin-right: auto;
                line-height: 8rem;
                text-align: center;

                &:first-line {
                  color:$colorPrimary;
                }
              }
            }

            &.splide {
              margin-left: auto;
              margin-right: auto;
            }

            &.subheadline {
              width:100%;
              margin-top:4rem;

              h4 {
                margin-left: auto;
                margin-right: auto;
              }
            }
          }
        }

        &.description {
          padding: 6rem 0 4rem 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .webcrossing-image-slider {
    @include spaceX(2.5rem);

    &:before {
      height:17rem;
    }

    > .wrapper {
      @include spaceY(4rem);

      .section {
        &.slider {
          padding: 0 2rem;

          .box {
            &.splide {
              .splide__arrows {
                width: 100%;

                .splide__arrow {
                  width:2rem;
                  height:2rem;
                  bottom: 50%;
                  transform: translate(0, 50%);

                  &--prev {
                    left: -3rem;

                    &:before {
                      font-size: 1.2rem;
                    }
                  }

                  &--next {
                    right: -3rem;

                    &:before {
                      font-size: 1.2rem;
                    }
                  }
                }
              }
            }

            &.headline {
              h3 {
                font-size: 2.7rem;
                line-height: 2.9rem;
              }
            }

            &.subheadline {
              text-align: center;
              margin-top: 3rem
            }
          }
        }

        &.description {
          padding: 5rem 0 3rem;

          p {
            font-size: 1.4rem;
            line-height: 2rem;
            margin-bottom: 3rem;
          }

          a {
            font-size: 1.1rem;
            line-height: 2.4rem;
          }
        }
      }
    }
  }
}
