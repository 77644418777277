.webcrossing-grid {
  @include spaceX(2.5rem);
  background-color: $colorGrey;

  > .wrapper {
    @include content(medium);
    @include spaceY(12.5rem);
    display:flex;
    flex-wrap:wrap;
    justify-content: center;

    .section {
      width:100%;

      &.grid {
        display:flex;
        flex-wrap:wrap;
        justify-content: center;
        gap:2rem;

        .box {
          width:calc((100% - 4rem) / 3);
          max-width: 45.7rem;
          margin:0;

          &.content {
            background-color:$colorWhite;
            padding:7rem 2rem;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;

            h4 {
              color:$colorPrimary;
              text-align: center;
            }

            h3 {
              color:$colorPrimary;
              text-align: center;
              max-width: 36rem;
            }

            a {
              font-weight: 500;
              font-size: 1.4rem;
              line-height: 3rem;
              letter-spacing: 4px;
              text-transform: uppercase;
              color:$colorPrimary;
              text-align: center;
              padding-bottom: 4px;

              &.arrow {
                border-bottom:2px solid;
                border-color:transparent;

                &:before {
                  font-family: "Font Awesome 6 Pro";
                  content: "\f078";
                  width: 2rem;
                  display: inline-block;
                }

                &:hover {
                  border-color:$colorPrimary;

                  &:before {
                    content: "\f054";
                  }
                }
              }
            }
          }

          &.image {
            padding-bottom: 39.2%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
      }

      &.description {
        padding:12rem 2.5rem 2.5rem 2.5rem;

        p {
          font-weight: 400;
          font-size: 2rem;
          line-height: 3rem;
          text-align: center;
          letter-spacing: 1.5px;
          color:$colorPrimary;
          max-width:80rem;
          margin: 0 auto;
        }
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  .webcrossing-grid {
    > .wrapper {
      .section {
        &.grid {
          .box {
            &.content {
              padding:2rem 1rem;

              h3 {
                font-size: 3rem;
                line-height: 4.2rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .webcrossing-grid {
    padding-left: 0;
    padding-right: 0;

    > .wrapper {
      padding-bottom: 5rem;
      padding-top: 0;

      .section {
        &.grid {
          position: relative;
          gap: 0;

          .box {
            width: 100%;

            &.content {
              padding: 2rem;

              h3 {
                font-size: 2.4rem;
                line-height: 2.9rem;
              }

              a {
                font-size: 1.1rem;
                line-height: 2.4rem;
              }
            }

            &.image {
              padding-bottom: 74%;
            }

            &.middle {
              width: calc(100% - 9rem);
              min-height: 36rem;
              margin-top: -12rem;
            }

            &.right {
              display:none;
            }
          }
        }

        &.description {
          padding: 3rem 2.5rem 0 2.5rem;

          p {
            font-size: 1.4rem;
            line-height: 2rem;
          }
        }
      }
    }
  }
}
