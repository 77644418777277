.webcrossing-opening-hours {
  @include spaceX(2.5rem);

  > .wrapper {
    @include content(small);
    @include spaceY(27rem);
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    gap: 8rem;

    .section {
      h3 {
        margin-right:auto;
        margin-bottom:7rem;
        color:$colorPrimary;
        max-width:45rem;
      }

      ul {
        list-style: none;

        li,
        td {
          font-weight: 400;
          font-size: 2rem;
          line-height: 3rem;
          letter-spacing: 1.5px;
          color:$colorPrimary;
        }
      }

      &.left {
        width:calc(100% - 68rem);

        ul {
          position: relative;

          li {
            &[data-type="arrow"] {
              @include overlay($width: 3rem, $height: 3rem, $z-index: 5);
              transition: top 0.5s;
              font-family: "Font Awesome 6 Pro";
              font-weight: 300;
              color:$colorSecondary;

              &:after {
                content: "\f061";
              }
            }

            &[data-type="trigger"] {
              margin-bottom:3rem;
              cursor:pointer;
              transition: margin 0.5s;

              &.active {
                color:$colorSecondary;
                margin-left: 6rem;
                transition: margin 0.5s;
              }
            }
          }
        }
      }

      &.right {
        width:60rem;
        max-width: 100%;
        overflow-x: auto;

        ul {
          min-width: 330px;

          li {
            display:none;

            &.active {
              display:block;
            }

            table {
              width: 100%;
              border-collapse:collapse;

              thead {
                display:none;
              }

              tbody {
                tr {
                  border-bottom:1px solid $colorPrimary;

                  &:last-child {
                    border-bottom:0;
                  }

                  td {
                    padding: 2rem 1rem 2rem 0;

                    &.day {
                      &.mobile {
                        display:none;
                      }
                    }

                    &.am,
                    &.pm {
                      font-weight: 500;
                      font-size: 1.4rem;
                      line-height: 3rem;
                      letter-spacing: 4px;
                      text-transform: uppercase;
                      color:$colorPrimary;
                    }

                    &.pm {
                      text-align:right;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  .webcrossing-opening-hours {
    > .wrapper {
      padding-top: 11rem;
      padding-bottom: 11rem;
      gap: 4rem;

      .section {
        &.left {
          width: calc(100% - 58rem);
        }

        &.right {
          width: 50rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $smallDesktop) {
  .webcrossing-opening-hours {
    > .wrapper {
      .section {
        &.left {
          width:100%;
        }

        &.right {
          width:100%;
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .webcrossing-opening-hours {
    @include spaceX(1.5rem);

    > .wrapper {
      .section {
        h3 {
          font-size: 2.4rem;
          line-height: 2.9rem;
          margin-bottom: 3rem;
        }

        ul {
          li {
            font-size: 1.4rem;
            line-height: 2rem;
          }

          td {
            font-size: 1.4rem;
            line-height: 2rem;
            text-transform: uppercase;
          }
        }

        &.left {
          ul {
            li {
              &[data-type="trigger"] {
                margin-bottom:1rem;

                &.active {
                  margin-left: 2rem;
                }
              }

              &[data-type="arrow"] {
                font-size:1.2rem;
                width: 1rem;
              }
            }
          }
        }

        &.right {
          ul {
            li {
              table {
                tbody {
                  tr {
                    td {
                      padding: 2rem 1rem 0 0;

                      &.day {
                        &.desktop {
                          display:none;
                        }

                        &.mobile {
                          display:block;
                        }
                      }

                      &.am,
                      &.pm {
                        font-size: 1.1rem;
                        line-height: 2.4rem;
                      }
                    }
                  }
                }
              }
            }
          }

        }
      }
    }
  }
}
