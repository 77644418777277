.webcrossing-nav {
  @include spaceX(2.5rem);
  background-color: $colorWhite;
  position: relative;
  z-index: 11;
  top:0;
  width:100%;
  max-width: 192rem;
  border-top: 1px solid $colorBlack;

  &.sticky {
    position:fixed;
  }

  > .wrapper {
    @include content(medium);
    display:flex;
    flex-wrap:wrap;
    justify-content: space-between;
    align-items: center;
    min-height: 54px;

    > .section {
      &.navigation {
        width: 100%;
        margin-right: 4%;
      }
    }
  }
}

@media only screen and (max-width: 1850px) {
  .webcrossing-nav {
    > .wrapper {
      > .section {
        &.navigation {
          margin-right: 13%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1800px) {
  .webcrossing-nav {
    > .wrapper {
      > .section {
        &.navigation {
          margin-right: 14%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1750px) {
  .webcrossing-nav {
    > .wrapper {
      > .section {
        &.navigation {
          margin-right: 15%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1700px) {
  .webcrossing-nav {
    > .wrapper {
      > .section {
        &.navigation {
          margin-right: 16%;
        }
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  .webcrossing-nav {
    display: none;
  }
}