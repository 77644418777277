.webcrossing-image-banner {
  > .wrapper {
    @include content(medium);

    .section {
      &.image {
        width: 100%;
        margin:0;
        position: static;
        background: transparent;

        img {
          display: block;
          width:100%;
        }
      }
    }
  }
}